import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import MailIcon from '@mui/icons-material/Mail';
import Toolbar from '@mui/material/Toolbar';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DeleteIcon from '@mui/icons-material/Delete';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PercentIcon from '@mui/icons-material/Percent';
import LoginIcon from '@mui/icons-material/Login';
import Badge from '@mui/material/Badge';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import Logo from 'assets/logo.jpeg';
import { useDispatch, useSelector } from 'react-redux';
import { sumBy } from 'lodash';
import { removeToCart } from 'redux-store/features/cart';
import SnackBarMessage from 'components/SnackBarMessage';
import { Button, Menu, MenuItem } from '@mui/material';
import LoginPopupModel from './LoginPopupModel';
import RegisterPopupModel from './RegisterPopupModel';
import ForgotPasswordModel from './ForgotPasswordModel';
import {
  setForgotPasswordModalStatus,
  setLoginModalStatus,
  setRegisterModalStatus,
} from 'redux-store/features/common';
import { useUserAuthContext } from 'contexts/UserAuthContext';
import FacebookIcon from 'assets/facebook.svg';
import YoutubeIcon from 'assets/youtube.svg';
import TwitterIcon from 'assets/twitter.svg';
import InstagramIcon from 'assets/instagram.svg';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CustomizedProgressBars from 'components/CustomizedProgressBars';
import { setProductList } from 'redux-store/features/products';
import { HashLink } from 'react-router-hash-link';

const drawerWidth = 240;

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

export default function MainLayout(props) {
  const { window } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [profileItemAnchorEl, setProfileItemAnchorEl] = React.useState(null);
  const { cartItems } = useSelector((state) => state.cart);
  const { productList } = useSelector((state) => state.products);
  const {
    profieInfo,
    loginModalStatus,
    registerModalStatus,
    apiLoading,
    forgotPasswordModalStatus,
  } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toggleAuth } = useUserAuthContext();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileItemClose = () => {
    setProfileItemAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const removeItemInCartList = (item, index) => {
    const updateProductList = [...productList];
    const productIndex = updateProductList.findIndex(
      (singleItem) => singleItem._id === item._id,
    );
    const updateItem = { ...updateProductList[productIndex] };
    updateItem.quantity = 0;
    updateProductList[productIndex] = updateItem;
    dispatch(setProductList(updateProductList));

    dispatch(removeToCart(index));
  };
  return (
    <>
      {apiLoading && <CustomizedProgressBars />}

      <Box sx={{ display: 'flex' }}>
        <SnackBarMessage />
        <CssBaseline />

        <AppBar
          position='fixed'
          style={{
            background: 'rgb(247 247 247)',
            boxShadow: '0 8px 20px 0 rgba(0, 0, 0, 0.05)',
          }}>
          <Box className='top-sub-header'>
            <p>Welcome to Our Refer and Earn Program! Join with us Today</p>
          </Box>
          <Grid container sx={{ margin: '0 auto' }} maxWidth='lg'>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
              className='main-header-container'>
              <Toolbar>
                <IconButton
                  onClick={() => navigate('/')}
                  style={{ background: 'none' }}>
                  <img src={Logo} alt='logo' />
                </IconButton>
              </Toolbar>
              <Toolbar>
                <Box
                  className='top-main-menu'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                    display: { xs: 'none', sm: 'flex' },
                  }}>
                  <Link to='/'>Home</Link>
                  <Link to='/products'>Our Products</Link>

                  <HashLink to='/#how'>How it works</HashLink>
                </Box>
              </Toolbar>
              <Toolbar>
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  style={{
                    marginRight: '25px',
                  }}
                  onClick={handleClick}>
                  <StyledBadge
                    badgeContent={cartItems.length}
                    color='secondary'>
                    <ShoppingCartIcon htmlColor='#000' />
                  </StyledBadge>
                </IconButton>

                {profieInfo ? (
                  <IconButton
                    aria-label='open drawer'
                    className='sigin-account-btn'
                    style={{
                      marginRight: '25px',
                    }}
                    onClick={() => navigate('/profile')}>
                    <AccountCircleIcon htmlColor='#000' />
                    <span>Profile</span>
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label='open drawer'
                    className='sigin-account-btn'
                    style={{
                      marginRight: '25px',
                    }}
                    onClick={() => dispatch(setLoginModalStatus(true))}>
                    <AccountCircleIcon htmlColor='#000' />
                    <span>Sign In</span>
                  </IconButton>
                )}

                {/* <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton> */}
              </Toolbar>
            </Box>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}>
              {cartItems.length > 0 ? (
                <>
                  <ul className='cart-items-list'>
                    {cartItems.map((item, index) => (
                      <li key={'cart-item' + index}>
                        <div className='title'>{item.title}</div>
                        <div className='price'>
                          ₹ {item.price} x {item.quantity}
                        </div>

                        <div className='quantity'>₹ {item.total_price}</div>
                        <div className='action'>
                          <IconButton
                            color='red'
                            onClick={() => removeItemInCartList(item, index)}>
                            <DeleteIcon color='error' />
                          </IconButton>
                        </div>
                      </li>
                    ))}
                    <li style={{ marginTop: '15px' }}>
                      <div className='title' style={{ fontWeight: 'bold' }}>
                        Total
                      </div>
                      <div className='price'></div>
                      <div className='total-value'>
                        ₹{' '}
                        {sumBy(cartItems, (item) => parseInt(item.total_price))}
                      </div>
                    </li>
                  </ul>
                  <Button
                    onClick={() => {
                      handleClose();
                      navigate('/checkout-order');
                    }}
                    className='view-cart-button-top'>
                    View Cart
                  </Button>
                </>
              ) : (
                <h4 style={{ padding: '0px 24px' }}>Your cart is empty</h4>
              )}
            </Popover>

            <Menu
              id='simple-menu'
              sx={{ mt: '45px', width: '400px' }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              anchorEl={profileItemAnchorEl}
              keepMounted
              open={Boolean(profileItemAnchorEl)}
              onClose={handleProfileItemClose}>
              {profieInfo ? (
                <>
                  <MenuItem classes={{ root: 'profile-list-name' }}>
                    <Typography variant='h4' className='profile-list-name'>
                      {profieInfo.name}
                    </Typography>
                  </MenuItem>
                  <MenuItem>
                    <ListItemIcon>
                      <PercentIcon size='35px' htmlColor='#fa416e' />
                    </ListItemIcon>
                    <ListItemText>Commision History</ListItemText>
                  </MenuItem>
                  <MenuItem>
                    <ListItemIcon>
                      <ReceiptLongIcon size='35px' htmlColor='#fa416e' />
                    </ListItemIcon>
                    <ListItemText>Transaction History</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => dispatch(toggleAuth(false))}>
                    <ListItemIcon>
                      <LogoutOutlinedIcon size='35px' htmlColor='#fa416e' />
                    </ListItemIcon>
                    <ListItemText>Sing Out</ListItemText>
                  </MenuItem>
                </>
              ) : (
                <MenuItem onClick={() => dispatch(setLoginModalStatus(true))}>
                  <ListItemIcon>
                    <LoginIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Sing In</ListItemText>
                </MenuItem>
              )}
            </Menu>
          </Grid>
        </AppBar>

        <Box
          component='main'
          sx={{
            width: '100%',
          }}>
          <Toolbar />
          <Outlet />
          <Box className='footer-section'>
            <Grid container sx={{ margin: '0 auto' }} maxWidth='lg'>
              <Grid
                container
                spacing={2}
                sx={{ marginTop: '10px', marginBottom: '20px' }}>
                <Grid item md={4} xs={12}>
                  <h2>RASGEN SOLUTIONS</h2>
                  <p>
                    Welcome to our community! We're excited to introduce our
                    Refer and Earn program, where you can earn fantastic rewards
                    simply by sharing your love for our grocery products with
                    your friends and family. Discover how easy it is to save
                    more on your grocery shopping while helping your loved ones
                    enjoy the quality and convenience of our products.
                  </p>
                </Grid>
                <Grid item md={2} xs={12}>
                  <h2>ABOUT</h2>
                  <ul className='footer-link'>
                    <li>
                      <Link to='#'>About us</Link>
                    </li>
                    <li>
                      <HashLink to='/#how'>How it works</HashLink>
                    </li>
                    <li>
                      <Link to='#'>Contact us</Link>
                    </li>
                    <li>
                      <Link to='whyjoin'>Why Join?</Link>
                    </li>
                    <li>
                      <Link to='faq'>FAQ</Link>
                    </li>
                  </ul>
                </Grid>
                <Grid item md={3} xs={12}>
                  <h2>CONSUMER POLICY</h2>
                  <ul className='footer-link'>
                    <li>
                      <Link to='privacy-policy'>Privacy policy</Link>
                    </li>
                    <li>
                      <Link to='terms-conditions'>Terms & conditions</Link>
                    </li>
                    <li>
                      <Link to='#'>Return Policy</Link>
                    </li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={3}>
                  <h2>FOLLOW US</h2>
                  <ul className='follow-links'>
                    <li>
                      <a href='https://www.facebook.com/' target='_blank'>
                        <img src={FacebookIcon} alt='' />
                      </a>
                    </li>
                    <li>
                      <a href='https://www.twitter.com/' target='_blank'>
                        <img src={TwitterIcon} alt='' />
                      </a>
                    </li>
                    <li>
                      <a href='https://www.instgram.com/' target='_blank'>
                        <img src={InstagramIcon} alt='' />
                      </a>
                    </li>
                    <li>
                      <a href='https://www.youtube.com/' target='_blank'>
                        <img src={YoutubeIcon} alt='' />
                      </a>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Grid>
            <Grid container sx={{ margin: '0 auto' }} maxWidth='lg'>
              <Box className='copy-right-section'>
                <Box>
                  © Copyright {new Date().getFullYear()} Rasgen Solutions, All
                  rights reserved.
                </Box>
                <Box>
                  Developed By{' '}
                  <a href='http://www.bangaloreadsolutions.com' target='_blank'>
                    Bangalore AdSolutions
                  </a>
                </Box>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Box>

      <LoginPopupModel
        show={loginModalStatus}
        close={() => dispatch(setLoginModalStatus(false))}
      />
      <RegisterPopupModel
        show={registerModalStatus}
        close={() => dispatch(setRegisterModalStatus(false))}
      />
      <ForgotPasswordModel
        show={forgotPasswordModalStatus}
        close={() => dispatch(setForgotPasswordModalStatus(false))}
      />
    </>
  );
}
