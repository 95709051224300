import { createSlice } from '@reduxjs/toolkit';

const initalState = {
  productList: [],
  imageBaseUrl: '',
};

export const productSlice = createSlice({
  name: 'products',
  initialState: initalState,
  reducers: {
    setProductList: (state, action) => {
      state.productList = action.payload;
    },
    setImageBaseUrl: (state, action) => {
      state.imageBaseUrl = action.payload;
    },
  },
});

export const { setProductList, setImageBaseUrl } = productSlice.actions;

export default productSlice.reducer;
