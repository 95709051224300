import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormikInput from 'components/FormikInput';
import CloseIcon from '@mui/icons-material/Close';
import { Form, Formik } from 'formik';
import { useUserAuthContext } from 'contexts/UserAuthContext';

import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  setApiLoading,
  setForgotPasswordModalStatus,
  setLoginModalStatus,
  setMessageShow,
  setProfileInfo,
  setRegisterModalStatus,
} from 'redux-store/features/common';
import { useNavigate } from 'react-router-dom';
import axios from 'config/user/instance';

const validationSchema = yup.object({
  userName: yup.string().required('Enter your Email/Phone number'),
  password: yup.string().required('Enter your password'),
});

const style = {
  width: '430px',
  overflowY: 'hidden',
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '0px',
  boxShadow: 24,
  position: 'relative',
  p: 4,
};

const LoginPopupModel = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setAuthState } = useUserAuthContext();
  const { cartItems } = useSelector((state) => state.cart);

  const initialValues = {
    userName: '',
    password: '',
  };

  const singInForm = (data) => {
    dispatch(setApiLoading(true));
    axios.post('/users/login', data).then((res) => {
      dispatch(setApiLoading(false));

      if (res.data.status) {
        localStorage.setItem('app-token', res.data.token);
        localStorage.setItem('user-info', JSON.stringify(res.data));
        setAuthState({
          token: res.data.access_token,
          auth: true,
        });
        dispatch(setProfileInfo(res.data));
        dispatch(setLoginModalStatus(false));
        if (cartItems.length === 0) {
          navigate('/profile');
        }
      } else {
        dispatch(
          setMessageShow({
            msg: 'Invalid Credentials!',
            open: true,
            severity: 'error',
          }),
        );
      }
    });
  };
  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={props.show}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box
        sx={style}
        justifyContent={'center'}
        display={'block'}
        alignContent={'center'}
        alignItems={'center'}
        alignSelf={'center'}>
        <IconButton className='login-form-modal-close' onClick={props.close}>
          <CloseIcon />
        </IconButton>
        <Box
          component='div'
          sx={{ display: 'flex', flexDirection: 'column' }}
          alignContent={'center'}
          alignItems={'center'}
          alignSelf={'center'}>
          <Typography
            variant='h4'
            sx={{
              align: 'center',
            }}>
            Hello !
          </Typography>
          <Typography
            variant='h6'
            sx={{
              align: 'center',
            }}>
            Sign in to your account
          </Typography>

          <Box sx={{ mt: 5 }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize
              onSubmit={(data) => singInForm(data)}>
              {(formikProps) => {
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <FormikInput
                          name='userName'
                          label='Email/Phone number*'
                          formikProps={formikProps}
                          options={undefined}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormikInput
                          name='password'
                          inputType='password'
                          label='Password*'
                          formikProps={formikProps}
                          options={undefined}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ textAlign: 'right' }}>
                        <Button
                          className='fogot-password-button'
                          type='button'
                          onClick={() => {
                            dispatch(setLoginModalStatus(false));
                            dispatch(setForgotPasswordModalStatus(true));
                          }}>
                          Forgot Password
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Button className='form-login-button' type='submit'>
                          Login
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Box>
          <Box>
            <Typography
              variant='h6'
              sx={{
                fontSize: '15px',
                fontWeight: '400',
                marginTop: '22px',
              }}>
              Don't have account?{' '}
              <Button
                onClick={() => {
                  dispatch(setLoginModalStatus(false));
                  dispatch(setRegisterModalStatus(true));
                }}>
                Create
              </Button>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default LoginPopupModel;
