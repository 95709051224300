import { createSlice } from '@reduxjs/toolkit';

const initalState = {
  cartItems: [],
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState: initalState,
  reducers: {
    addToCart: (state, action) => {
      state.cartItems = [...action.payload];
    },
    removeToCart: (state, action) => {
      const cartItems = [...state.cartItems];
      cartItems.splice(action.payload, 1);
      state.cartItems = cartItems;
    },
    makeEmptyCart: (state, action) => {
      state.cartItems = [];
    },
  },
});

export const { addToCart, removeToCart, makeEmptyCart } = cartSlice.actions;

export default cartSlice.reducer;
