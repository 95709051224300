import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { setMessageHide } from 'redux-store/features/common';

const SnackBarMessage = () => {
  const dispatch = useDispatch();
  const { msg, open, severity } = useSelector((state) => state.common.message);

  const { snackBarMsgPosition } = useSelector((state) => state.common);

  const handleClose = () => {
    dispatch(setMessageHide());
  };
  return (
    <Snackbar
      open={open}
      anchorOrigin={snackBarMsgPosition}
      autoHideDuration={3000}
      onClose={handleClose}>
      <MuiAlert
        variant='filled'
        onClose={handleClose}
        severity={severity}
        sx={{ width: '100%' }}>
        {msg}
      </MuiAlert>
    </Snackbar>
  );
};

export default SnackBarMessage;
