/* eslint-disable react/prop-types */
import List from '@mui/material/List';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import HomeIcon from '@mui/icons-material/Home';
import IconButton from '@mui/material/IconButton';
import BallotIcon from '@mui/icons-material/Ballot';
import PeopleIcon from '@mui/icons-material/People';

import OpenArrow from 'assets/icons/openArrow.svg';
import ShortLogo from 'assets/short-logo.jpg';
import Logo from 'assets/logo.jpeg';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const DrawerContent = ({ open, handleDrawerClose, classes }) => {
  const navigate = useNavigate();

  const redirectLink = (url) => {
    navigate(url);
  };
  return (
    <>
      <DrawerHeader className='drawer-heading'>
        {open ? (
          <img
            className='logo-header'
            src={Logo}
            alt=''
            style={{ width: '141px' }}
          />
        ) : (
          <img
            className='short-logo-header'
            src={ShortLogo}
            alt=''
            style={{ width: '48px' }}
          />
        )}

        {open && (
          <IconButton onClick={handleDrawerClose}>
            <img
              className={classes.drawerHeaderArrowIcon}
              src={OpenArrow}
              alt=''
            />
          </IconButton>
        )}
      </DrawerHeader>
      <Divider />
      <List className='left-menu-sidebar'>
        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            onClick={() => redirectLink('/admin/')}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}>
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary='Dashboard' sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            onClick={() => redirectLink('admin/users')}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}>
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary='Users' sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            onClick={() => redirectLink('admin/products')}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}>
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}>
              <BallotIcon />
            </ListItemIcon>
            <ListItemText primary='Products' sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            onClick={() => redirectLink('admin/orders')}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}>
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}>
              <ShoppingBagIcon />
            </ListItemIcon>
            <ListItemText primary='Orders' sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
    </>
  );
};

export default DrawerContent;
