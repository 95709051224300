import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { UserAuthContextProvider } from 'contexts/UserAuthContext';
import { AdminAuthContextProvider } from 'contexts/AdminAuthContext';
import { Provider } from 'react-redux';

import store from 'redux-store/store';
import './index.css';
import App from './App';

const theme = createTheme();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AdminAuthContextProvider>
          <UserAuthContextProvider>
            <App />
          </UserAuthContextProvider>
        </AdminAuthContextProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
