/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import axios from 'config/user/instance';
import { useDispatch } from 'react-redux';
import { setProfileInfo } from 'redux-store/features/common';
import CircularProgress from '@mui/material/CircularProgress';

// eslint-disable-next-line import/no-cycle
import UserAxiosContext from './UserAxiosContext';

export const UserAuthContext = React.createContext({});

export function UserAuthContextProvider(props) {
  const { children } = props;

  const dispatch = useDispatch();
  const [authInfo, setAuthInfo] = useState({
    token: '',
    isLoading: true,
    auth: false,
    isAdminAccess: false,
  });

  useEffect(() => {
    const token = localStorage.getItem('app-token');
    const adminAccess = localStorage.getItem('app-is-admin-access');

    if (!token) {
      setAuthInfo({
        ...authInfo,
        auth: false,
        isLoading: false,
      });
    } else {
      // axios.get('/profile').then((res) => {
      //   dispatch(setProfileInfo(res.data.user));
      // });

      setAuthInfo({
        ...authInfo,
        auth: true,
        isLoading: false,
        token,
        isAdminAccess: !!adminAccess,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setAuthState = (state) => {
    setAuthInfo(state);
  };

  const toggleAuth = (val) => {
    setAuthInfo({ ...authInfo, isLoading: true });
    if (!val) {
      localStorage.removeItem('app-token');
      localStorage.removeItem('user-info');
      localStorage.removeItem('app-is-admin-access');
      window.location.href = '/';
    }
    setAuthInfo({ ...authInfo, auth: val, isLoading: false });
  };

  const redirect401 = () => {
    setAuthInfo({ ...authInfo, auth: false });
    localStorage.removeItem('app-token');
    window.location.href = '/401';
  };

  if (authInfo.isLoading)
    return (
      <div
        className='d-flex justify-content-center align-items-center'
        style={{ height: '100vh' }}>
        <CircularProgress />
      </div>
    );

  return (
    <UserAuthContext.Provider
      value={{ authInfo, setAuthState, toggleAuth, redirect401 }}>
      <UserAxiosContext>{children}</UserAxiosContext>
    </UserAuthContext.Provider>
  );
}

export const useUserAuthContext = () => React.useContext(UserAuthContext);
