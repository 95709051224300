import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormikInput from 'components/FormikInput';
import CloseIcon from '@mui/icons-material/Close';
import { Form, Formik } from 'formik';
import { useUserAuthContext } from 'contexts/UserAuthContext';

import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  setApiLoading,
  setForgotPasswordModalStatus,
  setLoginModalStatus,
  setMessageShow,
  setProfileInfo,
  setRegisterModalStatus,
} from 'redux-store/features/common';
import { useNavigate } from 'react-router-dom';
import axios from 'config/user/instance';

const validationSchema = yup.object({
  email: yup.string().required('Enter your Email'),
});
const resetPasswordValidationSchema = yup.object({
  otp_code: yup.string().required('Enter you OTP'),
  password: yup.string().required('Enter you new password'),
});

const style = {
  width: '430px',
  overflowY: 'hidden',
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '0px',
  boxShadow: 24,
  position: 'relative',
  p: 4,
};

const ForgotPasswordModel = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    email: '',
  };
  const resetPasswordInitialValues = {
    email: '',
    otp_code: '',
    password: '',
  };

  const [showChangePassword, setShowChnagePassword] = useState(false);
  const [email, setEmail] = useState('');
  useEffect(() => {
    setShowChnagePassword(false);
  }, []);

  const submitForgotPassword = (data) => {
    dispatch(setApiLoading(true));
    setEmail(data.email);
    axios.post('/users/forgot-password', data).then((res) => {
      dispatch(setApiLoading(false));
      if (res.data.status) {
        setShowChnagePassword(true);
      } else {
        dispatch(
          setMessageShow({
            msg: 'Invalid email!',
            open: true,
            severity: 'error',
          }),
        );
      }
    });
  };
  const resendOTP = () => {
    dispatch(setApiLoading(true));
    axios
      .post('/users/forgot-password', {
        email,
      })
      .then((res) => {
        dispatch(setApiLoading(false));
        if (res.data.status) {
          setShowChnagePassword(true);
          dispatch(
            setMessageShow({
              msg: 'OTP resent successfully!',
              open: true,
              severity: 'success',
            }),
          );
        } else {
          dispatch(
            setMessageShow({
              msg: 'Invalid email!',
              open: true,
              severity: 'error',
            }),
          );
        }
      });
  };
  const submitResetPassword = (data) => {
    dispatch(setApiLoading(true));
    const formData = data;
    formData.email = email;
    axios.post('/users/forgot-change-password', formData).then((res) => {
      dispatch(setApiLoading(false));
      if (res.data.status) {
        dispatch(
          setMessageShow({
            msg: 'Password Updated Successfully!. Please login and use same password',
            open: true,
            severity: 'success',
          }),
        );
        dispatch(setForgotPasswordModalStatus(false));
      } else {
        dispatch(
          setMessageShow({
            msg: 'Invalid OTP!',
            open: true,
            severity: 'error',
          }),
        );
      }
    });
  };

  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={props.show}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box
        sx={style}
        justifyContent={'center'}
        display={'block'}
        alignContent={'center'}
        alignItems={'center'}
        alignSelf={'center'}>
        <IconButton
          className='login-form-modal-close'
          onClick={() => {
            setShowChnagePassword(false);
            props.close();
          }}>
          <CloseIcon />
        </IconButton>
        <Box
          component='div'
          sx={{ display: 'flex', flexDirection: 'column' }}
          alignContent={'center'}
          alignItems={'center'}
          alignSelf={'center'}>
          <Typography
            variant='h4'
            sx={{
              align: 'center',
            }}>
            Hello !
          </Typography>
          <Typography
            variant='h6'
            sx={{
              align: 'center',
            }}>
            Forgot Password
          </Typography>

          <Box sx={{ mt: 5 }}>
            {showChangePassword ? (
              <Formik
                initialValues={resetPasswordInitialValues}
                validationSchema={resetPasswordValidationSchema}
                enableReinitialize
                onSubmit={(data) => submitResetPassword(data)}>
                {(formikProps) => {
                  return (
                    <Form>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <FormikInput
                            name='otp_code'
                            label='Enter your six digit otp'
                            formikProps={formikProps}
                            options={undefined}
                            inputType='number'
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <FormikInput
                            name='password'
                            inputType='password'
                            label='Enter your new password*'
                            formikProps={formikProps}
                            options={undefined}
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          style={{ textAlign: 'right' }}>
                          <Button type='button' onClick={resendOTP}>
                            Resend OTP
                          </Button>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <Button className='form-login-button' type='submit'>
                            Update Password
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(data) => submitForgotPassword(data)}>
                {(formikProps) => {
                  return (
                    <Form>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <FormikInput
                            name='email'
                            label='Enter your email'
                            formikProps={formikProps}
                            options={undefined}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <Button className='form-login-button' type='submit'>
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ForgotPasswordModel;
