import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import SnackBarMessage from "components/SnackBarMessage";

function AuthLayout() {
  const { apiLoading } = useSelector((state) => state.common);

  return (
    <Box sx={{ display: "flex" }}>
      <SnackBarMessage />
      {apiLoading && (
        <Box className="full-page-loader">
          <CircularProgress />
        </Box>
      )}

      <Outlet />
    </Box>
  );
}

export default AuthLayout;
