import React from 'react';

const Profile = React.lazy(() => import('User/features/Profile'));
const WithAuthRoutes = [
  {
    path: '/profile',
    exact: true,
    name: 'Profile',
    component: Profile,
  },
];

export default WithAuthRoutes;
