import { configureStore } from '@reduxjs/toolkit';

import common from 'redux-store/features/common';
import cart from 'redux-store/features/cart';
import products from 'redux-store/features/products';

const store = configureStore({
  reducer: {
    common,
    cart,
    products,
  },
});

export const RootState = typeof store.getState;
export const AppDispatch = typeof store.dispatch;
export default store;
