import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormikInput from 'components/FormikInput';
import CloseIcon from '@mui/icons-material/Close';
import { Form, Formik } from 'formik';
import { useUserAuthContext } from 'contexts/UserAuthContext';
import axios from 'config/user/instance';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  setApiLoading,
  setLoginModalStatus,
  setMessageShow,
  setProfileInfo,
  setRegisterModalStatus,
} from 'redux-store/features/common';
import { useNavigate } from 'react-router-dom';

const validationSchema = yup.object({
  name: yup.string().required('Enter your full name'),
  email: yup.string().email().required('Enter your email address'),
  phoneNumber: yup
    .string()
    .matches(/^[6-9][0-9]{9}$/, 'Please enter valid phone number')
    .required('Please enter phone number'),
  password: yup.string().required('Enter your password'),
});

const style = {
  width: '430px',
  overflowY: 'hidden',
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '0px',
  boxShadow: 24,
  position: 'relative',
  p: 4,
};

const RegisterPopupModel = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setAuthState } = useUserAuthContext();
  const { cartItems } = useSelector((state) => state.cart);

  const initialValues = {
    name: '',
    email: '',
    password: '',
    phoneNumber: '',
  };

  const singupForm = (data) => {
    dispatch(setApiLoading(true));
    axios.post('/users/register', data).then((res) => {
      dispatch(setApiLoading(false));
      if (res.data.status) {
        localStorage.setItem('app-token', res.data.token);
        localStorage.setItem('user-info', JSON.stringify(res.data));
        setAuthState({
          token: res.data.access_token,
          auth: true,
        });
        dispatch(setProfileInfo(res.data));
        dispatch(setRegisterModalStatus(false));
        if (cartItems.length === 0) {
          navigate('/profile');
        }
      } else {
        dispatch(
          setMessageShow({
            msg: res.data.message,
            open: true,
            severity: 'error',
          }),
        );
      }
    });
  };
  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={props.show}
      onClose={props.close}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box
        sx={style}
        justifyContent={'center'}
        display={'block'}
        alignContent={'center'}
        alignItems={'center'}
        alignSelf={'center'}>
        <IconButton className='login-form-modal-close' onClick={props.close}>
          <CloseIcon />
        </IconButton>
        <Box
          component='div'
          sx={{ display: 'flex', flexDirection: 'column' }}
          alignContent={'center'}
          alignItems={'center'}
          alignSelf={'center'}>
          <Typography
            variant='h4'
            sx={{
              align: 'center',
            }}>
            Hello !
          </Typography>
          <Typography
            variant='h6'
            sx={{
              align: 'center',
            }}>
            Create your account
          </Typography>

          <Box sx={{ mt: 5 }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize
              onSubmit={(data) => singupForm(data)}>
              {(formikProps) => {
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <FormikInput
                          name='name'
                          label='Full Name*'
                          formikProps={formikProps}
                          options={undefined}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormikInput
                          name='email'
                          label='Email*'
                          formikProps={formikProps}
                          options={undefined}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormikInput
                          name='phoneNumber'
                          label='Phone Number*'
                          formikProps={formikProps}
                          options={undefined}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormikInput
                          name='password'
                          inputType='password'
                          label='Password*'
                          formikProps={formikProps}
                          options={undefined}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Button className='form-login-button' type='submit'>
                          Register
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Box>
          <Box>
            <Typography
              variant='h6'
              sx={{
                fontSize: '15px',
                fontWeight: '400',
                marginTop: '22px',
              }}>
              Already have an account?{' '}
              <Button
                onClick={() => {
                  dispatch(setRegisterModalStatus(false));
                  dispatch(setLoginModalStatus(true));
                }}>
                Login
              </Button>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default RegisterPopupModel;
