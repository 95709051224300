import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const instance = axios.create({ baseURL: API_URL });

instance.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';
// instance.defaults.headers.patch['Content-Type'] = 'application/json';
// instance.defaults.headers.common['Authorization'] = AUTH_TOKEN

instance.interceptors.request.use(
  (config) => {
    // Do something before request is sent

    const token = localStorage.getItem('app-token');
    // config.headers.Authorization = token ? `Token ${token}` : ''
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

export default instance;
