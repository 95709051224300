import { useContext, useMemo } from 'react';
import instance from 'config/user/instance';

// eslint-disable-next-line import/no-cycle
import { AdminAuthContext } from './AdminAuthContext';

const AdminAxiosContext = ({ children }) => {
  const { toggleAuth } = useContext(AdminAuthContext);

  useMemo(() => {
    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        // if (error.response?.status === 401) {
        //   toggleAuth(false);
        // }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      },
    );
  }, [toggleAuth]);

  return children;
};

export default AdminAxiosContext;
