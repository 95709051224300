import React from 'react';

const Home = React.lazy(() => import('User/features/Home'));
const Products = React.lazy(() => import('User/features/Products'));
const CheckoutOrder = React.lazy(() => import('User/features/CheckoutOrder'));
const AboutUs = React.lazy(() => import('User/features/Aboutus'));
const ContactUs = React.lazy(() => import('User/features/ContactUs'));
const AboutTeam = React.lazy(() => import('User/features/AboutTeam'));
const PrivacyPolicy = React.lazy(() => import('User/features/PrivacyPolicy'));
const WhyJoin = React.lazy(() => import('User/features/WhyJoin'));
const Faq = React.lazy(() => import('User/features/FAQ'));
const TermsConditions = React.lazy(() =>
  import('User/features/TermsConditions'),
);
const WithAuthRoutes = [
  { path: '/', exact: true, name: 'Home', component: Home },
  {
    path: '/checkout-order',
    exact: true,
    name: 'Home',
    component: CheckoutOrder,
  },
  {
    path: '/about-us',
    exact: true,
    name: 'About Us',
    component: AboutUs,
  },
  {
    path: '/Products',
    exact: true,
    name: 'Products',
    component: Products,
  },
  {
    path: '/contact-us',
    exact: true,
    name: 'Contactu Us',
    component: ContactUs,
  },
  {
    path: '/about-team',
    exact: true,
    name: 'About Team',
    component: AboutTeam,
  },
  {
    path: '/terms-conditions',
    exact: true,
    name: 'TermsConditions',
    component: TermsConditions,
  },
  {
    path: '/privacy-policy',
    exact: true,
    name: 'privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: '/faq',
    exact: true,
    name: 'faq',
    component: Faq,
  },
  {
    path: '/whyjoin',
    exact: true,
    name: 'whyjoin',
    component: WhyJoin,
  },
];

export default WithAuthRoutes;
