import { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from 'User/containers/MainLayout';
import AuthLayout from 'Admin/containers/AuthLayout';
import AdminMainLayout from 'Admin/containers/MainLayout';

import { useUserAuthContext } from 'contexts/UserAuthContext';
import { useAdminAuthContext } from 'contexts/AdminAuthContext';
import AdminAuthRoutes from 'routes/admin/auth';
import AdminRoutes from 'routes/admin/dashboard';
import UserWithAuthRoutes from 'routes/user/withauth';
import WithOutAuthRoutes from 'routes/user/withoutauth';
import CustomizedProgressBars from 'components/CustomizedProgressBars';
import './main.scss';

function App() {
  const { authInfo } = useUserAuthContext();
  const { adminAuthInfo } = useAdminAuthContext();
  console.log('adminAuthInfo', adminAuthInfo);
  return (
    <BrowserRouter>
      <Suspense fallback={<CustomizedProgressBars />}>
        <Routes>
          <Route
            element={
              adminAuthInfo?.auth ? <AdminMainLayout /> : <AuthLayout />
            }>
            {adminAuthInfo?.auth
              ? AdminRoutes.map(
                  (route) =>
                    route.component && (
                      <Route
                        key={route.path}
                        path={route.path}
                        name={route.name}
                        element={<route.component />}
                      />
                    ),
                )
              : AdminAuthRoutes.map(
                  (route) =>
                    route.component && (
                      <Route
                        key={route.path}
                        path={route.path}
                        name={route.name}
                        element={<route.component />}
                      />
                    ),
                )}
          </Route>
          <Route element={<MainLayout />}>
            {UserWithAuthRoutes.map(
              (route) =>
                route.component && (
                  <Route
                    key={route.path}
                    path={route.path}
                    name={route.name}
                    element={<route.component />}
                  />
                ),
            )}
            {WithOutAuthRoutes.map(
              (route) =>
                route.component && (
                  <Route
                    key={route.path}
                    path={route.path}
                    name={route.name}
                    element={<route.component />}
                  />
                ),
            )}
            <Route path='*' element={<Navigate to='/' replace />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
