import { createSlice } from '@reduxjs/toolkit';

const initalState = {
  apiLoading: false,
  message: {
    msg: '',
    open: false,
    severity: 'success',
  },
  loginModalStatus: false,
  registerModalStatus: false,
  forgotPasswordModalStatus: false,
  snackBarMsgPosition: {
    vertical: 'top',
    horizontal: 'center',
  },
  profieInfo:
    localStorage.getItem('user-info') !== undefined
      ? JSON.parse(localStorage.getItem('user-info'))
      : '',
};

export const commonSlice = createSlice({
  name: 'common',
  initialState: initalState,
  reducers: {
    setApiLoading: (state, action) => {
      state.apiLoading = action.payload;
    },
    setMessageShow: (state, action) => {
      state.message = action.payload;
    },
    setSnackBarMsgPosition: (state, action) => {
      state.snackBarMsgPosition = action.payload;
    },
    setMessageHide: (state) => {
      state.message.open = false;
    },
    setProfileInfo: (state, action) => {
      state.profieInfo = action.payload;
    },
    setLoginModalStatus: (state, action) => {
      state.loginModalStatus = action.payload;
    },
    setRegisterModalStatus: (state, action) => {
      state.registerModalStatus = action.payload;
    },
    setForgotPasswordModalStatus: (state, action) => {
      state.forgotPasswordModalStatus = action.payload;
    },
  },
});

export const {
  setApiLoading,
  setMessageShow,
  setMessageHide,
  setSnackBarMsgPosition,
  setLoginModalStatus,
  setRegisterModalStatus,
  setForgotPasswordModalStatus,
  setProfileInfo,
} = commonSlice.actions;

export default commonSlice.reducer;
