import React from 'react';

const Dashboard = React.lazy(() => import('Admin/features/Dashboard'));
const Users = React.lazy(() => import('Admin/features/Users'));
const Products = React.lazy(() => import('Admin/features/Products'));
const Orders = React.lazy(() => import('Admin/features/Orders'));
const DashboardRoutes = [
  // { path: '/', exact: true, name: 'Home', component: Dashboard },
  { path: '/admin/', exact: true, name: 'Home', component: Dashboard },
  { path: '/admin/dashboard', exact: true, name: 'Home', component: Dashboard },
  { path: '/admin/users', exact: true, name: 'Home', component: Users },
  { path: '/admin/products', exact: true, name: 'Home', component: Products },
  { path: '/admin/orders', exact: true, name: 'Home', component: Orders },
];

export default DashboardRoutes;
