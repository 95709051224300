/* eslint-disable default-case */
/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import SnackBarMessage from 'components/SnackBarMessage';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';

import MenuIcon from '@mui/icons-material/Menu';

import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Logo from 'assets/logo.jpeg';
import OpenArrow from 'assets/icons/openArrow.svg';
import DrawerContent from './DrawerContent';
import { useAdminAuthContext } from 'contexts/AdminAuthContext';

const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  topHeaderArrowIcon: {
    position: 'absolute',
    left: '0px',
    cursor: 'pointer',
    zIndex: '10000',
    top: '11px',
    '@media (max-width: 767px)': {
      display: 'none !important',
    },
  },
  drawerHeaderArrowIcon: {
    transform: 'rotate(180deg)',
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: 'rgb(140, 82, 86)',
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function MainLayout() {
  const { profieInfo } = useSelector((state) => state.common);
  const { apiLoading } = useSelector((state) => state.common);
  const { toggleAuth } = useAdminAuthContext();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(window.innerWidth >= 767);
  const [headerLeft, setHeaderLeft] = useState(240);
  // const [headerLeft, setHeaderLeft] = useState(65);

  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleLogout = () => {
    toggleAuth(false);
  };

  const handleDrawerOpen = () => {
    setHeaderLeft(240);
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setHeaderLeft(65);
    setOpen(false);
  };

  const divStyle = {
    width: `calc(100% - ${headerLeft}px)`,
    left: `${headerLeft === 65 ? headerLeft : 0}px`,
  };

  let pageName = 'Dashboard';

  switch (location.pathname) {
    case '/validate/results':
      pageName = 'Validate';
      break;
    case '/members/validate':
      pageName = 'Validate';
      break;
    case '/members/API':
      pageName = 'API';
      break;
    case '/profile':
      pageName = 'Profile';
      break;
  }

  const locationRedirect = (path) => {
    setAnchorElUser(null);
    navigate(path);
  };

  return (
    <>
      <SnackBarMessage />
      {apiLoading && (
        <Box className='full-page-loader'>
          <CircularProgress />
        </Box>
      )}

      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          className='app-bar-header'
          position='fixed'
          open={open}
          style={window.innerWidth >= 767 ? divStyle : null}>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            className={classes.topHeaderArrowIcon}
            edge='start'
            sx={{
              position: 'absolute',
              ...(open && { display: 'none' }),
            }}>
            <img src={OpenArrow} alt='' />
          </IconButton>

          <Toolbar
            className='header-toolbar-align'
            style={{ display: 'flex', justifyContent: 'space-between' }}>
            {window.innerWidth >= 767 ? (
              <Typography variant='h6' noWrap component='div'>
                {pageName}
              </Typography>
            ) : (
              <>
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  edge='start'
                  onClick={handleDrawerOpen}
                  sx={{ mr: 2, display: { sm: 'none' } }}>
                  <MenuIcon />
                </IconButton>

                <img className='logo-header' src={Logo} alt='' />
              </>
            )}
            <Box className='right-top-header-align'>
              <Tooltip title='Open settings'>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar sx={{ bgcolor: 'red' }}>R</Avatar>
                </IconButton>
              </Tooltip>

              <Menu
                sx={{ mt: '45px', width: '400px' }}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                classes={{ root: 'top-nav-profile-menu-items' }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}>
                <MenuItem
                  classes={{ root: 'profile-list-menu' }}
                  onClick={handleLogout}>
                  <LogoutOutlinedIcon size='23px' htmlColor='#fa416e' />
                  <Typography variant='h5' fontSize={'16px'}>
                    Sing Out
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>

        {window.innerWidth >= 767 ? (
          <Drawer variant='permanent' open={open}>
            <DrawerContent
              open={open}
              handleDrawerClose={handleDrawerClose}
              classes={classes}
            />
          </Drawer>
        ) : (
          <MuiDrawer variant='temporary' open={open}>
            <DrawerContent
              open={open}
              handleDrawerClose={handleDrawerClose}
              classes={classes}
            />
          </MuiDrawer>
        )}

        <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <Outlet />
        </Box>
      </Box>
    </>
  );
}

export default MainLayout;
